exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-logg-ut-jsx": () => import("./../../../src/pages/logg-ut.jsx" /* webpackChunkName: "component---src-pages-logg-ut-jsx" */),
  "component---src-templates-case-template-js": () => import("./../../../src/templates/case-template.js" /* webpackChunkName: "component---src-templates-case-template-js" */),
  "component---src-templates-cases-template-js": () => import("./../../../src/templates/cases-template.js" /* webpackChunkName: "component---src-templates-cases-template-js" */),
  "component---src-templates-competence-template-js": () => import("./../../../src/templates/competence-template.js" /* webpackChunkName: "component---src-templates-competence-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-courses-template-js": () => import("./../../../src/templates/courses-template.js" /* webpackChunkName: "component---src-templates-courses-template-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/front-page.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-laboratories-template-js": () => import("./../../../src/templates/laboratories-template.js" /* webpackChunkName: "component---src-templates-laboratories-template-js" */),
  "component---src-templates-laboratory-template-js": () => import("./../../../src/templates/laboratory-template.js" /* webpackChunkName: "component---src-templates-laboratory-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-login-template-js": () => import("./../../../src/templates/login-template.js" /* webpackChunkName: "component---src-templates-login-template-js" */),
  "component---src-templates-plain-template-js": () => import("./../../../src/templates/plain-template.js" /* webpackChunkName: "component---src-templates-plain-template-js" */),
  "component---src-templates-proteketonline-template-js": () => import("./../../../src/templates/proteketonline-template.js" /* webpackChunkName: "component---src-templates-proteketonline-template-js" */),
  "component---src-templates-register-template-js": () => import("./../../../src/templates/register-template.js" /* webpackChunkName: "component---src-templates-register-template-js" */),
  "component---src-templates-statistic-template-js": () => import("./../../../src/templates/statistic-template.js" /* webpackChunkName: "component---src-templates-statistic-template-js" */),
  "component---src-templates-statistics-template-js": () => import("./../../../src/templates/statistics-template.js" /* webpackChunkName: "component---src-templates-statistics-template-js" */),
  "component---src-templates-success-template-js": () => import("./../../../src/templates/success-template.js" /* webpackChunkName: "component---src-templates-success-template-js" */),
  "component---src-templates-webinar-template-js": () => import("./../../../src/templates/webinar-template.js" /* webpackChunkName: "component---src-templates-webinar-template-js" */)
}

