import './src/styles/global.css'
import './src/styles/splide.css'
import '@splidejs/react-splide/css/core'
import { Amplify } from 'aws-amplify';
import config from './src/aws-exports'; // path to your aws-exports file

Amplify.configure(config);

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}
